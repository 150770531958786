import React, { useCallback } from "react";
import { BOT_IMAGE, BOT_NAME } from "@configs";
import {
  TextMessage,
  ImageMessage,
  CarouselMessage,
  OptionsMessage,
  ImageMapMessage,
  FlexMessage,
  VideoMessage,
  AttachmentMessage,
  DeliveryStatus,
  DateText,
  EDateTextDirection,
  ImageCarousel,
} from "@shares/components";
import { useAppStore } from "@store/appStore";
import {
  EMessageStatus,
  EMessageType,
  ESender,
  ICombinedMessage,
  IMessageAction,
} from "@types";

import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";
import { createDataAttr, decodeHTML } from "@utils";
import { LocationMessage } from "@shares/components";
import root from "react-shadow";
export type IMessageItem = {
  message: ICombinedMessage;
  onSendAction: (action: IMessageAction) => void;
};

export const MessageItem = React.memo(
  ({ message, onSendAction }: React.PropsWithChildren<IMessageItem>) => {
    const { t } = useTranslation();
    let isUser: boolean;

    if (Array.isArray(message)) {
      isUser = message[0].sender === ESender.USER;
    } else {
      isUser = message.sender === ESender.USER;
    }
    const { botImage, botName } = useAppStore((state) => {
      return {
        botImage: state.settings.botImage ? state.settings.botImage : BOT_IMAGE,
        botName: state.settings.botName ? state.settings.botName : BOT_NAME,
      };
    }, shallow);
    const _renderMessage = useCallback((message: ICombinedMessage) => {
      if (message.type === EMessageType.IMAGE) {
        return (
          <a
            className="cursor-pointer"
            onClick={() => {
              const url = message.originalContentUrl;
              const newWindow = window.open();
              newWindow?.document.write(
                `<body style="background: black; padding: 0;margin: 0">
                <div style='margin: 0 auto; width: 100%; height: 100%;'><img style="position: absolute;
                top: 50%;
                left: 50%;
                max-width: 100%;
                max-height: 100%;
                height: auto;
                width: auto;
                transform: translate(-50%, -50%);" src="${url}" /></div>
                </body>`
              );
            }}
          >
            <ImageMessage message={message} />
          </a>
        );
      }
      if (message.type === EMessageType.TEXT) {
        return <TextMessage message={message.text} />;
      }
      if (message.type === EMessageType.CAROUSEL) {
        return (
          <CarouselMessage message={message} onSendAction={onSendAction} />
        );
      }
      if (message.type === EMessageType.BUTTONS) {
        return <OptionsMessage message={message} onSendAction={onSendAction} />;
      }
      if (message.type === EMessageType.VIDEO) {
        return <VideoMessage message={message} />;
      }
      if (message.type === EMessageType.FLEX) {
        return <FlexMessage message={message} onSendAction={onSendAction} />;
      }
      if (message.type === EMessageType.IMAGEMAP) {
        return (
          <ImageMapMessage message={message} onSendAction={onSendAction} />
        );
      }
      if (message.type === EMessageType.IMAGE_CAROUSEL) {
        return <ImageCarousel message={message} onSendAction={onSendAction} />;
      }
      if (message.type === EMessageType.ATTACHMENT) {
        return <AttachmentMessage message={message} />;
      }
      if (message.type === EMessageType.LOCATION) {
        return (
          <LocationMessage
            currentLocation={{
              lat: message.latitude,
              lng: message.longitude,
              description: message.description,
            }}
          />
        );
      }
      if (message.type === EMessageType.HTML) {
        return (
          <div
            className="html-message relative  overflow-hidden rounded-[15px] bg-white p-3"
            style={{
              transform: "scale(1)",
            }}
            {...createDataAttr("html-message")}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: decodeHTML(message.htmlContent),
              }}
            />
          </div>
        );
      }
      return null;
    }, []);

    const _renderMessages = () => {
      if (Array.isArray(message)) {
        return message.map((item: ICombinedMessage) => {
          if (item.type === EMessageType.TEXT && !item.text) return null;
          return (
            <div key={item.id}>
              {_renderMessage(item)}
              <DateText
                direction={EDateTextDirection.LEFT}
                str={item.createdAt}
              />
            </div>
          );
        });
      } else {
        return _renderMessage(message);
      }
    };

    const isNullMessage = (message: ICombinedMessage) => {
      // text
      if (message.type === EMessageType.TEXT && !message.text) {
        return true;
      }
      // image, video
      if (
        (message.type === EMessageType.IMAGE ||
          message.type === EMessageType.VIDEO) &&
        !message.previewImageUrl
      ) {
        return true;
      }

      return false;
    };

    const _renderUserMessage = () => {
      if (isNullMessage(message)) return null;
      return (
        <div
          className="flex justify-end gap-x-[10px] py-[20px] pr-[10px]"
          {...createDataAttr("user-message-item")}
        >
          <div className="min-w-[50px] flex-1" />
          <div className="flex max-w-[80%] flex-col">
            <div className="text-left">
              <div className="flex justify-end">{_renderMessage(message)}</div>
              <div className="flex items-center justify-end">
                <DeliveryStatus
                  isSending={message.status === EMessageStatus.SENDING}
                  isError={message.status === EMessageStatus.ERROR}
                  sendingText={t("sending.message")}
                  errorText={t("send.message.failed")}
                />
                <DateText
                  direction={EDateTextDirection.RIGHT}
                  str={message.createdAt}
                />
              </div>
            </div>
          </div>
        </div>
      );
    };
    const _renderBotMessage = () => {
      // Bot or Agent side
      if (isNullMessage(message)) return null;
      return (
        <div
          className="flex gap-x-[10px] px-[20px] py-[20px]"
          {...createDataAttr("bot-message-item")}
        >
          <img
            src={botImage}
            className={`max-h-[30px] min-h-[30px] min-w-[30px] max-w-[30px] rounded-full`}
          />
          <div className="flex max-w-[80%] flex-col">
            <div className="text-left">
              <div>{botName}</div>
              <div className="flex flex-col justify-start gap-5">
                {_renderMessages()}
              </div>
              <DateText
                direction={EDateTextDirection.LEFT}
                str={message.createdAt}
              />
            </div>
            <div className="min-w-[50px] flex-1" />
          </div>
        </div>
      );
    };

    return (
      <>
        {/* User side */}
        {isUser ? _renderUserMessage() : _renderBotMessage()}
      </>
    );
  }
);
